<template>
  <v-container
    v-bind="propsPageMainContainer"
    data-cy="organisations-create-page"
  >
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <v-card v-bind="propsPageMainCard">
          <div v-bind="propsPageTitleBar">
            <div v-bind="propsPageTitle">
              {{ $t("organisations.createTitle") }}
            </div>
            <div class="flex-grow-1"></div>
            <v-btn
              v-bind="propsButtonIconAction"
              small
              :to="{ name: 'organisations-index' }"
              data-cy="button-index"
            >
              <v-icon>$pageIndex</v-icon>
            </v-btn>
          </div>
          <OrganisationsCreate></OrganisationsCreate>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import OrganisationsCreate from "../components/OrganisationsCreate";

export default {
  name: "OrganisationsCreatePage",
  mixins: [Permissions],
  components: {
    OrganisationsCreate,
  },
};
</script>

<style scoped lang="scss"></style>
